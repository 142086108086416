import { createModel } from "@rematch/core";
import { GenUtils } from "../../constants/GenUtils";
import QueryService, {
    ContactUsParams,
    GoogleLoginParams,
    RoleParams,
    SignupParams,
    InvoiceParams,
    BranchParams
} from "../../services/query.service";
import { RootModel } from ".";
import Constants from "../../constants/Constants";
import { StoreInterface, Transaction, MerchantInput, Payout, Invoice, PaymentLinksInterface } from "../../types";
import { GridSortModel, GridFilterItem } from '@mui/x-data-grid';
import i18n from '../../i18n'; // Import your i18n configuration
import { PhoneNumber } from 'libphonenumber-js';

export interface IUser {
    id?: string;
    first_name: string;
    last_name: string;
    email: string;
}

type AuthPayload = {
    token: string;
    user: IUser;
    hash: string;
}

type PaginatedTransactions = {
    count: number,
    next?: string,
    previous?: string,
    results: Transaction[],
}

type PaginatedPaymentLinks = {
    count: number,
    next?: string,
    previous?: string,
    results: PaymentLinksInterface[],
}

type PaginatedPayouts = {
    count: number,
    next?: string,
    previous?: string,
    results: Payout[],
}

type PaginatedInvoices = {
    count: number,
    next?: string,
    previous?: string,
    results: Invoice[],
}

type StatisticValue = {
    value: string | number,
    unit: string
}

type Statistics = {
    revenue: StatisticValue,
    net: StatisticValue,
    payments: StatisticValue,
    customers: StatisticValue,
    refunds_value: StatisticValue,
    refunds_number: StatisticValue,
    refunds_percentage_from_revenue: StatisticValue,
    refunds_percentage_from_payments: StatisticValue,
    transactions: Transaction[]
    filtration: string
}

type UserState = {
    user?: IUser,
    auth: string,
    hash: string,
    stores: StoreInterface[],
    payouts: PaginatedPayouts,
    invoices: PaginatedInvoices,
    selectedStore?: StoreInterface,
    transactions: PaginatedTransactions,
    paymentLinks: PaginatedPaymentLinks,
    statistics: Statistics,
    useJustPassMeAuth?: boolean
}

let isGettingToken = false; // This will act as a flag to prevent duplicate requests

export const user = createModel<RootModel>()({
    state: {
        auth: "",
        stores: [],
        selectedStore: undefined,
        transactions: {
            count: 0,
            results: [],
        },
        paymentLinks: {
            count: 0,
            results: [],
        },
        payouts: {
            count: 0,
            results: [],
        },
        invoices: {
            count: 0,
            results: [],
        },
        hash: "",
        statistics: {
            revenue: {
                value: 0,
                unit: ''
            },
            net: {
                value: 0,
                unit: ''
            },
            payments: {
                value: 0,
                unit: ''
            },
            customers: {
                value: 0,
                unit: ''
            },
            refunds_value: {
                value: 0,
                unit: ''
            },
            refunds_number: {
                value: 0,
                unit: ''
            },
            refunds_percentage_from_revenue: {
                value: 0,
                unit: ''
            },
            refunds_percentage_from_payments: {
                value: 0,
                unit: ''
            },
            transactions: [],
            filtration: ""
        }
    } as UserState, // initial state
    reducers: {
        isAuthenticated(state, payload?: AuthPayload) {
            if (payload?.token) {
                return {
                    ...state,
                    auth: payload?.token,
                    user: payload.user,
                    hash: payload.hash,
                };
            }
            return { ...state }
        },
        setUseJustPassMeAuth(state, useJustPassMeAuth?: boolean) {
            return { ...state, useJustPassMeAuth };
        },
        setStores(state, stores: StoreInterface[]) {
            return { ...state, stores };
        },
        setSelectedStore(state, store: StoreInterface) {
            return { ...state, selectedStore: store };
        },
        setTransactions(state, transactions: PaginatedTransactions) {
            return { ...state, transactions };
        },
        setPaymentLinks(state, paymentLinks: PaginatedPaymentLinks) {
            return { ...state, paymentLinks };
        },
        setPayouts(state, payouts: PaginatedPayouts) {
            return { ...state, payouts };
        },
        setInvoices(state, invoices: PaginatedInvoices) {
            return { ...state, invoices };
        },
        logout(state) {
            return { ...state, auth: "" };
        },
        fetchStatistics(state, payload: Statistics) {
            return { ...state, statistics: payload };
        }
    },
    effects: (dispatch) => ({
        async signInAsync(payload: { email: string; password: string }) {
            try {
                const loginResponse = await QueryService.login(payload.email.toLowerCase(), payload.password);
                const token = loginResponse.data.token
                if (token) {
                    GenUtils.setToSyncStorage(Constants.ACCESS_TOKEN, token)
                    const fetchResponse = await QueryService.fetchProfile();
                    const intercomHash = await QueryService.intercomHash();
                    dispatch.user.isAuthenticated({
                        token,
                        user: fetchResponse.data,
                        hash: intercomHash.data.user_hash
                    });
                } else {
                    throw (loginResponse.data)
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
            }
        },
        async googleSignInAsync(payload: { google_id: string }) {
            try {
                const loginResponse = await QueryService.googleLogin(payload);
                const token = loginResponse.data.token
                if (token) {
                    GenUtils.setToSyncStorage(Constants.ACCESS_TOKEN, token)
                    const fetchResponse = await QueryService.fetchProfile();
                    const intercomHash = await QueryService.intercomHash();
                    dispatch.user.isAuthenticated({
                        token,
                        user: fetchResponse.data,
                        hash: intercomHash.data.user_hash
                    });
                } else {
                    throw (loginResponse.data)
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
            }
        },
        async getStoreAsync() {
            try {
                const { data } = await QueryService.fetchUserDashboard();
                dispatch.user.setStores(data);
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
            }
        },
        async getStoreTransactionsAsync(payload: {
            id: string,
            type: 'SANDBOX' | 'PRODUCTION',
            page_size?: number,
            page?: number,
            filter?: GridFilterItem[],
            sort?: GridSortModel,
            search?: string,
            branch_id?: string,
            payment_link_id?: string
        }) {
            try {
                const { data } = await QueryService.fetchTransactions(
                    payload.id,
                    payload.type,
                    payload.page_size ?? 15,
                    payload.page,
                    payload.filter,
                    payload.sort,
                    payload.search,
                    payload.branch_id,
                    payload.payment_link_id
                );
                console.log(data)
                if (data.count !== undefined) {
                    dispatch.user.setTransactions(data);
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
                console.log(JSON.stringify(e), 'error')
            }
        },
        async getStorePaymentLinksAsync(payload: {
            id: string,
            branchId?: string,
            page_size?: number,
            page?: number,
            filter?: GridFilterItem[],
            sort?: GridSortModel,
            search?: string
        }) {
            try {
                const { data } = await QueryService.fetchStorePaymentLinks(
                    payload.id,
                    payload.branchId, // Include branchId if provided
                    payload.page_size ?? 15,
                    payload.page,
                    payload.filter,
                    payload.sort,
                    payload.search
                );
                if (data.count !== undefined) {
                    dispatch.user.setPaymentLinks(data);
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error');
                console.log(JSON.stringify(e), 'error');
            }
        },
        async getStorePayoutsAsync(payload: {
            id: string,
            page_size?: number,
            page?: number,
            filter?: GridFilterItem[],
            sort?: GridSortModel,
            search?: string
        }) {
            try {
                const { data } = await QueryService.fetchPayout(
                    payload.id,
                    payload.page_size ?? 15,
                    payload.page,
                    payload.filter,
                    payload.sort,
                    payload.search);
                if (data.count !== undefined) {
                    dispatch.user.setPayouts(data);
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
            }
        },
        async getStoreInvoicesAsync(payload: {
            id: string,
            page_size?: number,
            page?: number,
            filter?: GridFilterItem[],
            sort?: GridSortModel,
            search?: string
        }) {
            try {
                const { data } = await QueryService.fetchInvoices(
                    payload.id,
                    payload.page_size ?? 15,
                    payload.page,
                    payload.filter,
                    payload.sort,
                    payload.search);
                if (data.count !== undefined) {
                    dispatch.user.setInvoices(data);
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
                console.log(JSON.stringify(e), 'error')
            }
        },
        async signUpAsync(payload: SignupParams) {
            try {
                const { data } = await QueryService.createProfile(payload);
                if (data) {
                    GenUtils.showToast(i18n.t("confirmationMail"), 'success')
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
            }
        },
        async forgotPasswordEmailConfirmationAsync(email: string) {
            try {
                const { data } = await QueryService.forgotPasswordEmailConfirm(email.toLowerCase())
                if (data) {
                    GenUtils.showToast(i18n.t("confirmationMail"), 'success')
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
            }
        },
        async isAuthenticatedAsync() {
            const authenticateWithToken = async (token: string, useJustPassMeAuth:boolean) => {
                const fetchResponse = await QueryService.fetchProfile();
                const intercomHash = await QueryService.intercomHash();
                dispatch.user.isAuthenticated({
                    token,
                    user: fetchResponse.data,
                    hash: intercomHash.data.user_hash,
                });
            }
            try {
                let useJustPassMeAuth = false;
                let token = await GenUtils.getFromSyncStorage(Constants.ACCESS_TOKEN)
                if (!token) {
                    // First visit check
                    if (!isGettingToken) {
                        isGettingToken = true;
                        try {
                            const { data } = await QueryService.getToken()
                            useJustPassMeAuth = data.useJustPassMeAuth
                            if (data.token) {
                                token = data.token
                                GenUtils.setToSyncStorage(Constants.ACCESS_TOKEN, data.token)
                            } else if (data.message){
                                console.log(data.message);
                            }
                        } catch (e) {
                            console.log(e)
                        } finally {
                            isGettingToken = false;
                        }
                    }
                }
                if (token) {
                    await authenticateWithToken(token, useJustPassMeAuth)
                }
                dispatch.user.setUseJustPassMeAuth(useJustPassMeAuth)
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
            }
        },
        async logoutAsync() {
            try {
                await QueryService.logout();
                const theme = await GenUtils.getFromSyncStorage('theme')
                await GenUtils.clear()
                GenUtils.setToSyncStorage('theme', theme as string)
                dispatch.user.logout();
                window.location.replace('/'); 
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
            }
        },
        async googleLoginAsync(payload: GoogleLoginParams) {
            try {
                const { data } = await QueryService.googleLogin(payload);
                if (data.token) {
                    GenUtils.setToSyncStorage(Constants.ACCESS_TOKEN, data.token)
                    const fetchResponse = await QueryService.fetchProfile();
                    const intercomHash = await QueryService.intercomHash();
                    dispatch.user.isAuthenticated({
                        token: data.token,
                        user: fetchResponse.data,
                        hash: intercomHash.data.user_hash
                    });
                } else {
                    throw (data)
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
            }
        },
        async intercomHashAsync() {
            try {
                const { data } = await QueryService.intercomHash();
                if (data.user_hash) {
                    return data.user_hash
                } else {
                    throw (data)
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
            }
        },
        async createStoreAsync(payload: {
            desired_payment_methods: string,
            business_name: string,
            arabic_business_name: string,
            store_phone_number: string,
            store_email: string,
            national_id: string,
            store_country: string,
            store_category: string,
            store_city: string,
            business_type: string,
            captchaToken: string
        }) {
            try {
                const { data } = await QueryService.createStore(payload);
                if (data.id) {
                    return data
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
                throw {
                    fields: Object.keys(e as any),
                    error: new Error(JSON.stringify(Object.keys(e as any)))
                }
            }
        },
        async updateStoreAsync(payload: MerchantInput) {
            try {
                const { data } = await QueryService.updateStore(payload);
                if (data.id) {
                    return data
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
                throw {
                    fields: Object.keys(e as any),
                    error: new Error(JSON.stringify(Object.keys(e as any)))
                }
            }
        },
        async passwordChangeAsync(payload: {
            currentPassword: string,
            password: string
        }) {
            try {
                const { data } = await QueryService.changePassword(payload)
                if (data) {
                    GenUtils.showToast(i18n.t('passwordChanged'), 'success')
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
            }
        },
        async transactionRefundAsync(payload: {
            transactionID: string,
            refund_amount: number
        }) {
            const { data } = await QueryService.refundTransaction(payload.transactionID, payload.refund_amount)
            if (data?.status === 'success') {
                GenUtils.showToast(i18n.t('transaction_refunded', { transactionID: payload.transactionID }), 'success')
            }
        },
        async contactUsAsync(payload: ContactUsParams) {
            try {
                const { data } = await QueryService.contactUs(payload)
                if (data === 'success') {
                    GenUtils.showToast(i18n.t('messageSent'), 'success')
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
            }
        },
        async verifySignupAsync(code: string) {
            try {
                const { data } = await QueryService.SignupVerify(code)
                if (data) {
                    GenUtils.showToast(i18n.t('emailVerified'), 'success')
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
                console.log(e)
            }
        },
        async addMemberToStoreAsync(addUserForm: RoleParams) {
            try {
                const { data } = await QueryService.addMembersToStore(addUserForm)
                if (data) {
                    GenUtils.showToast(i18n.t('memberAdded'), 'success')
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
                console.log(e)
            }
        },
        async editMemberToStoreAsync(addUserForm: RoleParams) {
            try {
                const { data } = await QueryService.editMembersToStore(addUserForm)
                if (data) {
                    GenUtils.showToast(i18n.t('memberUpdated'), 'success')
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
                console.log(e)
            }
        },
        async deleteMemberToStoreAsync(id: string) {
            try {
                const { data } = await QueryService.deleteMembersFromStore(id)
                if (data) {
                    GenUtils.showToast(i18n.t('memberDeleted'), 'success')
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
                console.log(e)
            }
        },
        async addBranchToStoreAsync(addBranchForm: BranchParams) {
            try {
                const { data } = await QueryService.addBranchToStore(addBranchForm)
                if (data) {
                    GenUtils.showToast(data.message, 'success')
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
                console.log(e)
            }
        },
        async editBranchToStoreAsync(addBranchForm: BranchParams) {
            try {
                const { data } = await QueryService.editBranchToStore(addBranchForm)
                if (data) {
                    GenUtils.showToast(data.message, 'success')
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
                console.log(e)
            }
        },
        async deleteBranchToStoreAsync(id: string) {
            try {
                const { data } = await QueryService.deleteBranchFromStore(id)
                if (data.status) {
                    GenUtils.showToast(i18n.t('branchActivated'), 'success')
                }
                else {
                    GenUtils.showToast(i18n.t('branchDeactivated'), 'success')
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
                console.log(e)
            }
        },
        async fetchStatisticsAsync(form: { storeId?: string, start: Date|null, end: Date|null, type: string}) {
            try {
                const { data } = await QueryService.fetchStatistics(form)
                if (data) {
                    dispatch.user.fetchStatistics(data)
                }
            } catch (e) {
                GenUtils.showToast('Error: ' + JSON.stringify(e), 'error')
                console.log(e)
            }
        }
    }),
});
